import React from 'react'
import Modal from '@mui/material/Modal';
import {FaSearch} from 'react-icons/fa'
import Box from '@mui/material/Box';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxHeight: '95%',
    borderRadius: '20px',
    backgroundColor: '#EEE9E3',
    boxShadow: 24,
    p: 4,
    paddingBottom: '60px',
    overflow: 'scroll',
  };

const DropUp = ({dropUp,handleDropUpModalOpen,handleDropUp,dropUpModalIsOpen,resDropUp,handleDropUpChoosenPlace,subCatVal }) => {
  return (
    <div className="mb-custom-4">
                    <label className="form-label fs-13" htmlFor="pickup">Enter Drop Address</label>
                    
                    {subCatVal === 'cab-to-airport' ? 
                      <button 
                      className={`form-control text-start text-dark  shadow-none fs-13`}
                      disabled={true}
                      >
                        Bangalore Airport Lounge, Kempegowda Int'l Airport Rd, A - Block, Gangamuthanahalli, Karnataka, India
                      </button>
                    :
                    //   <button 
                    //   className={`form-control text-start ${dropUp === ''?'color-customGray':'text-dark'}  shadow-none fs-13`} 
                    //   onClick={handleDropUpModalOpen}
                    //   >
                    //   {dropUp === ''? 'Pick Up Location': dropUp}
                    // </button>
                    <input type="text" placeholder='Dropup Location ' className="form-control text-start shadow-none fs-13"
                      onChange={(e)=>handleDropUp(e.target.value)}
                    />
                    }

                   <Modal
                        open={dropUpModalIsOpen}
                        onClose={handleDropUpModalOpen}
                    >
                        <Box sx={style}>
                            <div className='input-wrapper mb-5'>
                                <FaSearch/>
                                <input type="text" placeholder='Search Dropup place' className=" w-100 bg-transparent border-none shadow-none"
                                    onChange={(e)=>handleDropUp(e.target.value)}
                                />
                                <button className='go-btn bg-yellow text-dark' onClick={()=> handleDropUpChoosenPlace(dropUp)}>Go</button>
                            </div>
                            {resDropUp.status === 'OK' ? resDropUp.predictions.map((places,id)=>{
                                    return <div key={id} className='text-center mt-3' onClick={()=> handleDropUpChoosenPlace(places.description)}>{places.description}</div>
                            }) : <div className='text-center mt-3'>No Places Searched</div>}
                        </Box>
                    </Modal>
        </div>
  )
}

export default DropUp
