import React from 'react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Check from '../assets/images/success.webp';
import Warning from '../assets/images/warning.webp'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    borderRadius: '20px',
    backgroundColor: '#EEE9E3',
    boxShadow: 24,
    p: 4,
    paddingBottom: '30px',
    alignItems: 'center',
    textAlign: 'center'
  };

const Success = ({successModalIsOpen,successDetails,successModalClose,isLoading}) => {
  return (
    <Modal
        open={successModalIsOpen}
        onClose={successModalClose}
    >
        <Box sx={style}>
            {successDetails === 'Kindly Fill all details to book Cab. Thanks !!!' ? 
                <img src={Warning} width={120} height={120} alt="" />:
                <img src={Check} width={120} height={120} alt="" />
            }
            <h4 className='mt-3'>{successDetails}</h4>
        </Box>
    </Modal>
  )
}

export default Success
