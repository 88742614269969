import React from 'react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    height: '95%',
    borderRadius: '5px',
    backgroundColor: 'white',
    boxShadow: 24,
    p: 2,
    overflow: 'scroll',
    outline : 'none',
  };
 

const DatePicker = ({selectTime,ModalIsOpen,handleModalOpen,showTime}) => {
    
     
    
  return (
  <>
    <p>Choose Time</p>
    <div className='form-control' onClick={handleModalOpen}>
      {showTime}
    </div>

    <Modal
        open={ModalIsOpen}
        onClose={handleModalOpen}
    >
        <Box sx={style}>

            
        <div className="row bb pt-3 pb-2" onClick={()=>selectTime('00:00','12:00 AM')}>
            <label htmlFor='00:00' className="col" >12:00 AM</label> <div className="col text-end"><input type="radio" name="time" id="00:00"/></div>
        </div>
        <div className="row bb pt-3 pb-2" onClick={()=>selectTime('00:15','12:15 AM')}>
            <label htmlFor='00:15' className="col">12:15 AM</label> <div className="col text-end"><input type="radio" name="time" id="00:15"/></div>
        </div>
        <div className="row bb pt-3 pb-2" onClick={()=>selectTime('00:30','12:30 AM')}>
            <label htmlFor='00:30' className="col">12:30 AM</label> <div className="col text-end"><input type="radio" name="time" id="00:30"/></div>
        </div>
        <div className="row bb pt-3 pb-2" onClick={()=>selectTime('00:45','12:45 AM')}>
            <label htmlFor='00:45' className="col">12:45 AM</label> <div className="col text-end"><input type="radio" name="time" id="00:45"/></div>
        </div>


            <div className="row bb pt-3 pb-2"  onClick={()=>selectTime('01:00','01:00 AM')}>
                <label htmlFor='01:00' className="col">01:00 AM</label> <div className="col text-end"><input type="radio" name="time" id="01:00"/></div>
            </div>
            <div className="row bb pt-3 pb-2"  onClick={()=>selectTime('01:15','01:15 AM')}>
                <label htmlFor='01:15' className="col">01:15 AM</label> <div className="col text-end"><input type="radio" name="time" id="01:15"/></div>
            </div>
            <div className="row bb pt-3 pb-2"  onClick={()=>selectTime('01:30','01:30 AM')}>
                <label htmlFor='01:30' className="col">01:30 AM</label> <div className="col text-end"><input type="radio" name="time" id="01:30"/></div>
            </div>
            <div className="row bb pt-3 pb-2"  onClick={()=>selectTime('01:45','01:45 AM')}>
                <label htmlFor='01:45' className="col">01:45 AM</label> <div className="col text-end"><input type="radio" name="time" id="01:45"/></div>
            </div>

            <div className="row bb pt-3 pb-2"  onClick={()=>selectTime('02:00','02:00 AM')}>
                <label htmlFor='02:00' className="col">02:00 AM</label> <div className="col text-end"><input type="radio" name="time" id="02:00"/></div>
            </div>
            <div className="row bb pt-3 pb-2"  onClick={()=>selectTime('02:15','02:15 AM')}>
                <label htmlFor='02:15' className="col">02:15 AM</label> <div className="col text-end"><input type="radio" name="time" id="02:15"/></div>
            </div>
            <div className="row bb pt-3 pb-2"  onClick={()=>selectTime('02:30','02:30 AM')}>
                <label htmlFor='01:30' className="col">02:30 AM</label> <div className="col text-end"><input type="radio" name="time" id="02:30"/></div>
            </div>
            <div className="row bb pt-3 pb-2"  onClick={()=>selectTime('02:45','02:45 AM')}>
                <label htmlFor='02:45' className="col">02:45 AM</label> <div className="col text-end"><input type="radio" name="time" id="02:45"/></div>
            </div>

            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('03:00','03:00 AM')}>
                <label htmlFor='03:00' className="col">03:00 AM</label> <div className="col text-end"><input type="radio" name="time" id="03:00"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('03:15','03:15 AM')}>
                <label htmlFor='03:15' className="col">03:15 AM</label> <div className="col text-end"><input type="radio" name="time" id="03:15"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('03:30','03:30 AM')}>
                <label htmlFor='03:30' className="col">03:30 AM</label> <div className="col text-end"><input type="radio" name="time" id="03:30"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('03:45','03:45 AM')}>
                <label htmlFor='03:45' className="col">03:45 AM</label> <div className="col text-end"><input type="radio" name="time" id="03:45"/></div>
            </div>

            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('04:00','04:00 AM')}>
                <label htmlFor='04:00' className="col">04:00 AM</label> <div className="col text-end"><input type="radio" name="time" id="04:00"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('04:15','04:15 AM')}>
                <label htmlFor='04:15' className="col">04:15 AM</label> <div className="col text-end"><input type="radio" name="time" id="04:15"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('04:30','04:30 AM')}>
                <label htmlFor='04:30' className="col">04:30 AM</label> <div className="col text-end"><input type="radio" name="time" id="04:30"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('04:45','04:45 AM')}>
                <label htmlFor='04:45' className="col">04:45 AM</label> <div className="col text-end"><input type="radio" name="time" id="04:45"/></div>
            </div>

            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('05:00','05:00 AM')}>
                <label htmlFor='05:00' className="col">05:00 AM</label> <div className="col text-end"><input type="radio" name="time" id="05:00"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('05:15','05:15 AM')}>
                <label htmlFor='05:15' className="col">05:15 AM</label> <div className="col text-end"><input type="radio" name="time" id="05:15"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('05:30','05:30 AM')}>
                <label htmlFor='05:30' className="col">05:30 AM</label> <div className="col text-end"><input type="radio" name="time" id="05:30"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('05:45','05:45 AM')}>
                <label htmlFor='05:45' className="col">05:45 AM</label> <div className="col text-end"><input type="radio" name="time" id="05:45"/></div>
            </div>

            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('06:00','06:00 AM')}>
                <label htmlFor='06:00' className="col">06:00 AM</label> <div className="col text-end"><input type="radio" name="time" id="06:00"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('06:15','06:15 AM')}>
                <label htmlFor='06:15' className="col">06:15 AM</label> <div className="col text-end"><input type="radio" name="time" id="06:15"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('06:30','06:30 AM')}>
                <label htmlFor='06:30' className="col">06:30 AM</label> <div className="col text-end"><input type="radio" name="time" id="06:30"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('06:45','06:45 AM')}>
                <label htmlFor='06:45' className="col">06:45 AM</label> <div className="col text-end"><input type="radio" name="time" id="06:45"/></div>
            </div>

            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('07:00','07:00 AM')}>
                <label htmlFor='07:00' className="col">07:00 AM</label> <div className="col text-end"><input type="radio" name="time" id="07:00"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('07:15','07:15 AM')}>
                <label htmlFor='07:15' className="col">07:15 AM</label> <div className="col text-end"><input type="radio" name="time" id="07:15"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('07:30','07:30 AM')}>
                <label htmlFor='07:30' className="col">07:30 AM</label> <div className="col text-end"><input type="radio" name="time" id="07:30"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('07:45','07:45 AM')}>
                <label htmlFor='07:45' className="col">07:45 AM</label> <div className="col text-end"><input type="radio" name="time" id="07:45"/></div>
            </div>

            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('08:00','08:00 AM')}>
                <label htmlFor='08:00' className="col">08:00 AM</label> <div className="col text-end"><input type="radio" name="time" id="08:00"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('08:15','08:15 AM')}>
                <label htmlFor='08:15' className="col">08:15 AM</label> <div className="col text-end"><input type="radio" name="time" id="08:15"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('08:30','08:30 AM')}>
                <label htmlFor='08:30' className="col">08:30 AM</label> <div className="col text-end"><input type="radio" name="time" id="08:30"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('08:45','08:45 AM')}>
                <label htmlFor='08:45' className="col">08:45 AM</label> <div className="col text-end"><input type="radio" name="time" id="08:45"/></div>
            </div>

            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('09:00','09:00 AM')}>
                <label htmlFor='09:00' className="col">09:00 AM</label> <div className="col text-end"><input type="radio" name="time" id="09:00"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('09:15','09:15 AM')}>
                <label htmlFor='09:15' className="col">09:15 AM</label> <div className="col text-end"><input type="radio" name="time" id="09:15"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('09:30','09:30 AM')}>
                <label htmlFor='09:30' className="col">09:30 AM</label> <div className="col text-end"><input type="radio" name="time" id="09:30"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('09:45','09:45 AM')}>
                <label htmlFor='09:45' className="col">09:45 AM</label> <div className="col text-end"><input type="radio" name="time" id="09:45"/></div>
            </div>

            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('10:00','10:00 AM')}>
                <label htmlFor='10:00' className="col">10:00 AM</label> <div className="col text-end"><input type="radio" name="time" id="10:00"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('10:15','10:15 AM')}>
                <label htmlFor='10:15' className="col">10:15 AM</label> <div className="col text-end"><input type="radio" name="time" id="10:15"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('10:30','10:30 AM')}>
                <label htmlFor='10:30' className="col">10:30 AM</label> <div className="col text-end"><input type="radio" name="time" id="10:30"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('10:45','10:45 AM')}>
                <label htmlFor='10:45' className="col">10:45 AM</label> <div className="col text-end"><input type="radio" name="time" id="10:45"/></div>
            </div>

            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('11:00','11:00 AM')}>
                <label htmlFor='11:00' className="col">11:00 AM</label> <div className="col text-end"><input type="radio" name="time" id="11:00"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('11:15','11:15 AM')}>
                <label htmlFor='11:15' className="col">11:15 AM</label> <div className="col text-end"><input type="radio" name="time" id="11:15"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('11:30','11:30 AM')}>
                <label htmlFor='11:30' className="col">11:30 AM</label> <div className="col text-end"><input type="radio" name="time" id="11:30"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('11:45','11:45 AM')}>
                <label htmlFor='11:45' className="col">11:45 AM</label> <div className="col text-end"><input type="radio" name="time" id="11:45"/></div>
            </div>

            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('12:00','12:00 PM')}>
                <label htmlFor='12:00' className="col" >12:00 PM</label> <div className="col text-end"><input type="radio" name="time" id="12:00"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('12:15','12:15 PM')}>
                <label htmlFor='12:15' className="col">12:15 PM</label> <div className="col text-end"><input type="radio" name="time" id="12:15"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('12:30','12:30 PM')}>
                <label htmlFor='12:30' className="col">12:30 PM</label> <div className="col text-end"><input type="radio" name="time" id="12:30"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('12:45','12:45 PM')}>
                <label htmlFor='12:45' className="col">12:45 PM</label> <div className="col text-end"><input type="radio" name="time" id="12:45"/></div>
            </div>

            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('13:00','01:00 PM')}>
                <label htmlFor='13:00' className="col" >01:00 PM</label> <div className="col text-end"><input type="radio" name="time" id="13:00"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('13:15','01:15 PM')}>
                <label htmlFor='13:15' className="col">01:15 PM</label> <div className="col text-end"><input type="radio" name="time" id="13:15"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('13:30','01:30 PM')}>
                <label htmlFor='13:30' className="col">01:30 PM</label> <div className="col text-end"><input type="radio" name="time" id="13:30"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('13:45','01:45 PM')}>
                <label htmlFor='13:45' className="col">01:45 PM</label> <div className="col text-end"><input type="radio" name="time" id="13:45"/></div>
            </div>

            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('14:00','02:00 PM')}>
                <label htmlFor='14:00' className="col" >02:00 PM</label> <div className="col text-end"><input type="radio" name="time" id="14:00"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('14:15','02:15 PM')}>
                <label htmlFor='14:15' className="col">02:15 PM</label> <div className="col text-end"><input type="radio" name="time" id="14:15"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('14:30','02:30 PM')}>
                <label htmlFor='14:30' className="col">02:30 PM</label> <div className="col text-end"><input type="radio" name="time" id="14:30"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('14:45','02:45 PM')}>
                <label htmlFor='14:45' className="col">02:45 PM</label> <div className="col text-end"><input type="radio" name="time" id="14:45"/></div>
            </div>
            
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('15:00','03:00 PM')}>
                <label htmlFor='15:00' className="col" >03:00 PM</label> <div className="col text-end"><input type="radio" name="time" id="15:00"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('15:15','03:15 PM')}>
                <label htmlFor='15:15' className="col">03:15 PM</label> <div className="col text-end"><input type="radio" name="time" id="15:15"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('15:30','03:30 PM')}>
                <label htmlFor='15:30' className="col">03:30 PM</label> <div className="col text-end"><input type="radio" name="time" id="15:30"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('15:45','03:45 PM')}>
                <label htmlFor='15:45' className="col">03:45 PM</label> <div className="col text-end"><input type="radio" name="time" id="15:45"/></div>
            </div>

            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('16:00','04:00 PM')}>
                <label htmlFor='16:00' className="col" >04:00 PM</label> <div className="col text-end"><input type="radio" name="time" id="16:00"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('16:15','04:15 PM')}>
                <label htmlFor='16:15' className="col">04:15 PM</label> <div className="col text-end"><input type="radio" name="time" id="16:15"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('16:30','04:30 PM')}>
                <label htmlFor='16:30' className="col">04:30 PM</label> <div className="col text-end"><input type="radio" name="time" id="16:30"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('16:45','04:45 PM')}>
                <label htmlFor='16:45' className="col">04:45 PM</label> <div className="col text-end"><input type="radio" name="time" id="16:45"/></div>
            </div>

            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('17:00','05:00 PM')}>
                <label htmlFor='17:00' className="col" >05:00 PM</label> <div className="col text-end"><input type="radio" name="time" id="17:00"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('17:15','05:15 PM')}>
                <label htmlFor='17:15' className="col">05:15 PM</label> <div className="col text-end"><input type="radio" name="time" id="17:15"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('17:30','05:30 PM')}>
                <label htmlFor='17:30' className="col">05:30 PM</label> <div className="col text-end"><input type="radio" name="time" id="17:30"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('17:45','05:45 PM')}>
                <label htmlFor='17:45' className="col">05:45 PM</label> <div className="col text-end"><input type="radio" name="time" id="17:45"/></div>
            </div>

            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('18:00','06:00 PM')}>
                <label htmlFor='18:00' className="col" >06:00 PM</label> <div className="col text-end"><input type="radio" name="time" id="18:00"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('18:15','06:15 PM')}>
                <label htmlFor='18:15' className="col">06:15 PM</label> <div className="col text-end"><input type="radio" name="time" id="18:15"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('18:30','06:30 PM')}>
                <label htmlFor='18:30' className="col">06:30 PM</label> <div className="col text-end"><input type="radio" name="time" id="18:30"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('18:45','06:45 PM')}>
                <label htmlFor='18:45' className="col">06:45 PM</label> <div className="col text-end"><input type="radio" name="time" id="18:45"/></div>
            </div>

            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('19:00','07:00 PM')}>
                <label htmlFor='19:00' className="col" >07:00 PM</label> <div className="col text-end"><input type="radio" name="time" id="19:00"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('19:15','07:15 PM')}>
                <label htmlFor='19:15' className="col">07:15 PM</label> <div className="col text-end"><input type="radio" name="time" id="19:15"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('19:30','07:30 PM')}>
                <label htmlFor='19:30' className="col">07:30 PM</label> <div className="col text-end"><input type="radio" name="time" id="19:30"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('19:45','07:45 PM')}>
                <label htmlFor='19:45' className="col">07:45 PM</label> <div className="col text-end"><input type="radio" name="time" id="19:45"/></div>
            </div>

            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('20:00','08:00 PM')}>
                <label htmlFor='20:00' className="col" >08:00 PM</label> <div className="col text-end"><input type="radio" name="time" id="20:00"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('20:15','08:15 PM')}>
                <label htmlFor='20:15' className="col">08:15 PM</label> <div className="col text-end"><input type="radio" name="time" id="20:15"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('20:30','08:30 PM')}>
                <label htmlFor='20:30' className="col">08:30 PM</label> <div className="col text-end"><input type="radio" name="time" id="20:30"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('20:45','08:45 PM')}>
                <label htmlFor='20:45' className="col">08:45 PM</label> <div className="col text-end"><input type="radio" name="time" id="20:45"/></div>
            </div>

            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('21:00','09:00 PM')}>
                <label htmlFor='21:00' className="col" >09:00 PM</label> <div className="col text-end"><input type="radio" name="time" id="21:00"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('21:15','09:15 PM')}>
                <label htmlFor='21:15' className="col">09:15 PM</label> <div className="col text-end"><input type="radio" name="time" id="21:15"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('21:30','09:30 PM')}>
                <label htmlFor='21:30' className="col">09:30 PM</label> <div className="col text-end"><input type="radio" name="time" id="21:30"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('21:45','09:45 PM')}>
                <label htmlFor='21:45' className="col">09:45 PM</label> <div className="col text-end"><input type="radio" name="time" id="21:45"/></div>
            </div>

            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('22:00','10:00 PM')}>
                <label htmlFor='22:00' className="col" >10:00 PM</label> <div className="col text-end"><input type="radio" name="time" id="22:00"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('22:15','10:15 PM')}>
                <label htmlFor='22:15' className="col">10:15 PM</label> <div className="col text-end"><input type="radio" name="time" id="22:15"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('22:30','10:30 PM')}>
                <label htmlFor='22:30' className="col">10:30 PM</label> <div className="col text-end"><input type="radio" name="time" id="22:30"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('22:45','10:45 PM')}>
                <label htmlFor='22:45' className="col">10:45 PM</label> <div className="col text-end"><input type="radio" name="time" id="22:45"/></div>
            </div>


            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('23:00','11:00 PM')}>
                <label htmlFor='23:00' className="col" >11:00 PM</label> <div className="col text-end"><input type="radio" name="time" id="23:00"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('23:15','11:15 PM')}>
                <label htmlFor='23:15' className="col">11:15 PM</label> <div className="col text-end"><input type="radio" name="time" id="23:15"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('23:30','11:30 PM')}>
                <label htmlFor='23:30' className="col">11:30 PM</label> <div className="col text-end"><input type="radio" name="time" id="23:30"/></div>
            </div>
            <div className="row bb pt-3 pb-2" onClick={()=>selectTime('23:45','11:45 PM')}>
                <label htmlFor='23:45' className="col">11:45 PM</label> <div className="col text-end"><input type="radio" name="time" id="23:45"/></div>
            </div>

        </Box>
    </Modal>
  </>
  )
}

export default DatePicker
