import React from 'react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { ColorRing } from 'react-loader-spinner'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    borderRadius: '20px',
    backgroundColor: '#EEE9E3',
    boxShadow: 24,
    p: 4,
    alignItems: 'center',
    textAlign: 'center'
  };

const Loading = ({loadingModalIsOpen}) => {
  return (
    <Modal
        open={loadingModalIsOpen}
    >
        <Box sx={style}>
            <div className="text-center" style={{paddingBottom: '25px', paddingTop: '25px'}}>
            <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={['black']}
            />
            </div>
        </Box>
    </Modal>
  )
}

export default Loading
