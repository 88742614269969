import {  useEffect, useState } from 'react';
import './App.css';
import Contacticons from './components/Contacticons';
import Feature from './components/Feature';
import Form from './components/Form';
import Topframe from './components/Topframe';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


function App() {

  const [mainCat, setMainCat] = useState({
    val: 'airport',
    subCategory1: {
      catName: 'Airport to City',
      value: 'cab-from-airport'
    },
    subCategory2: {
      catName: 'City to Airport',
      value: 'cab-to-airport'
    }
  })


  const [subCat, setSubCat] = useState({
    val: 'cab-from-airport',
  })

  const [resPickUp, setResPickUp] = useState([])
  const [resDropUp, setResDropUp] = useState([])
  const [carTypes, setCarTypes] =useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [pickUp, setPickUp] = useState('');
  const [dropUp, setDropUp] = useState('');

  const majorCategory = (e)=>{
    if(e.target.value === 'airport'){
      setMainCat({
        val: e.target.value,
        subCategory1: {
          catName: 'Airport to City',
          value: 'cab-from-airport'
        },
        subCategory2: {
          catName: 'City to Airport',
          value: 'cab-to-airport'
        }
      })

      setSubCat({val: 'cab-from-airport'})
      // setDropUp('')
    }
    else if(e.target.value === 'outstation'){
      setMainCat({
        val: e.target.value,
        subCategory1: {
          catName: 'One Way',
          value: 'one-way'
        },
        subCategory2: {
          catName: 'Round Trip',
          value: 'round-trip'
        }
      })

      setSubCat({val: 'one-way'})
      // setPickUp('')
      // setDropUp('')
    }
    else{
      setMainCat({
        val: e.target.value,
        subCategory1: {
          catName: '4 Hours, 40 KM',
          value: '4hrs 40kms'
        },
        subCategory2: {
          catName: '8 Hours, 80 KM',
          value: '8hrs 80kms'
        }
      })

      setSubCat({val: '4hrs 40kms'})
      // setPickUp('')
      // setDropUp('')
    }  
  }

  const minorCategory = (e)=>{
    setSubCat({
      val : e.target.value,
    })
    if (e.target.value === 'cab-from-airport'){
      setDropUp('')
    }
    else if (e.target.value === 'cab-to-airport'){
      setPickUp('')
    }
    else{
      // setPickUp('')
      // setDropUp('')
    }
  }

  const fetchPickUp= async (place)=>{
    try {
      const pickUpRes = await fetch(`https://bangaloreairporttaxis.in/api/getlocation?input=${place}`)
      .then((res)=>{return res.json()})
      
      setResPickUp(pickUpRes);
    } catch (error) {
      console.log(error);
    }
    
  }

  const fetchDropUp= async (place)=>{
    try {
      const dropUpRes = await fetch(`https://bangaloreairporttaxis.in/api/getlocation?input=${place}`)
      .then((res)=>{return res.json()})
      
      setResDropUp(dropUpRes);
    } catch (error) {
      console.log(error);
    }
    
  }

  const fetchCars =async (types)=>{
    console.log(types)
    // console.log(`https://bangaloreairporttaxis.in/api/getfare?type=${types.val}`);
    try {
      const cars = await fetch(`https://bangaloreairporttaxis.in/api/getfare?type=${types}`)
      .then((res)=>{
        console.log(res)
        setIsLoading(false)
        return res.json()
      });
      console.log("cars",cars);
      setCarTypes(cars);
    } catch (error) {
      console.log(error)
    }
    
  }

  useEffect(()=>{
     setIsLoading(true)
  },[subCat])

  // useEffect(()=>{

  //   const handleInstallClick = async () => {
  //     if (!deferredPrompt) {
  //       console.log(deferredPrompt)
  //       return;
  //     }
  //     console.log('pop up')
  //     deferredPrompt.prompt();
  //     const result = await deferredPrompt.userChoice;
  //     setDeferredPrompt(null);
  //   };

  //   setTimeout(() => {
  //     handleInstallClick();
  //   }, 4000);
  
  // },[])

  return (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Contacticons/>
    <Topframe/>

    <Form 

      mainCat= {mainCat} 
      majorCategory = {majorCategory}
      subCatVal = {subCat.val}
      minorCategory = {minorCategory}
      fetchPickUp={fetchPickUp}
      fetchDropUp={fetchDropUp}
      fetchCars={fetchCars}
      resPickUp={resPickUp}
      resDropUp={resDropUp}
      carTypes={carTypes}
      isLoading = {isLoading}
      pickUp = {pickUp}
      dropUp = {dropUp}
      setDropUp={setDropUp}
      setPickUp={setPickUp}
      setIsLoading = {setIsLoading}
    />

    <Feature/>
  </LocalizationProvider>
  );
}

export default App;
